import React from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import Button2 from './Button2'

const Title = styled.h1`
font-size: ${props => props.theme.fontxxl};
text-transform: capitalize;
width: 80%;
color: ${props => props.theme.body};
align-self: flex-start;
margin: auto;

span{
text-transform: uppercase;
font-family: "Akaya Telivigala", cursive;
}

@media (max-width: 70em) {
  font-size: ${props => props.theme.fontxl};
}
@media (max-width: 48em) {
  align-self: center;
  text-align: center;
}
@media (max-width: 40em){
  width: 90%;
}


`;

const SubTitle = styled.h3`
    font-size: ${(props) => props.theme.fontlg};
    text-transform: capitalize;
    color: ${props => `rgba(${props.theme.textRgba}, 0.6)`};
    font-weight:600;
    margin-bottom: 1rem;
    width: 80%;
    align-self: flex-start;
    text-align: left;
    margin: auto;

    @media (max-width: 40em) {
      font-size: ${props => props.theme.fontmd};
      
    }

    @media (max-width: 48em) {
      align-self: center;
      text-align: center;
    }

`

const ButtonContainer = styled.div`
width: 80%;
align-self: flex-start;
margin-top: 20px;
margin-left: 50px;


@media (max-width: 48em) {
  align-self: center;
  text-align: center;

  button{
    margin: 0 auto;
  }
}

`
const TypeWriterText = () => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

    return (
     <>
     <Title>
        Investing on actors of
        <Typewriter
        options={{
          autoStart: true,
          loop:true,
        }}
        onInit={(typewriter) => {
          typewriter.typeString('<span>A new world.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span>Today.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span>Tomorrow.</span>')
            .pauseFor(2000)
            .deleteAll()
            .start();
        }}
      />
      
    </Title>
    <SubTitle> One Motto. One Target.</SubTitle>
    <ButtonContainer>
        <Button2 text="About us" onClick={() => scrollTo('about')} />
    </ButtonContainer>
     </>
  );
};

export default TypeWriterText