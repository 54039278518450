import React from 'react'
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCube, Navigation, Autoplay, Pagination } from 'swiper/modules';

import img1 from '../assets/Carousel/carousel1.png';
import img2 from '../assets/Carousel/carousel2.jpg';
import img3 from '../assets/Carousel/carousel3.jpg';

import Arrow from '../assets/Arrow.svg'

const Container = styled.div`
width: 37vw;
height: 79vh;

@media (max-width: 70em) {
  height: 60vh;
}
@media (max-width: 64em) {
  height: 50vh;
  width: 80vw;
}
@media (max-width: 48em) {
  height: 50vh;
  width: 80vw;
}
@media (max-width: 30em) {
  height: 45vh;
  width: 75vw;
}

.swiper{
    width:100%;
    height: 100%;
}

.swiper-slide{
  background-color: ${props => `rgba(${props.theme.textRgba},0.2)`};

  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next{
  color: ${props => props.theme.text};
  right: 0;
  height: 4rem;

  background-image: url(${Arrow});
  background-position: right;
  background-size: cover;

  &:after{
    display: none;
  }

  @media (max-width: 64em) {
    width: 3rem;
  }
  @media (max-width: 30em) {
    width: 2rem;
  }
}

.swiper-button-prev{
  color: ${props => props.theme.text};
  left: 0;
  height: 4rem;
  transform: rotate(180deg);
  background-image: url(${Arrow});
  background-position: right;
  background-size: cover;

  &:after{
    display: none;
  }

  @media (max-width: 64em) {
    width: 3rem;
  }
  @media (max-width: 30em) {
    width: 2rem;
  }
}
`

const Image = styled.img`
  max-width: 100%;
  max-height: 80%;
  `;


const Carousel = () => {
    return (
        <Container>
            <Swiper
        autoplay={{
          delay:5000,
          disableOnInteraction:false,
        }}
        pagination={{
          type:'indice',
        }}
        scrollbar={{
          draggable:true,
        }}
        navigation={true}
        effect={'cube'}
        grabCursor={true}
        cubeEffect={{
          shadow: false,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.64,
        }}
        modules={[EffectCube, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Image src={img1} alt="Logo" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={img2} alt="Logo" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={img3} alt="Logo" />
        </SwiperSlide>
      </Swiper>
        </Container>
    )
}

export default Carousel