import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
width: 100%;
height: 100%;
align-items: center;
justify-content: center;
display: flex;
`

const Join = () => {
    return (
        <nav class="bg-dark navbar-dark navbar">
            <div className="row col-12 d-flex justify-content-center text-white">
                <Title>Registration</Title>
            </div>
        </nav>
    )
}

export default Join
