import React from 'react'
import styled from 'styled-components'
import Carousel from '../../Carousel'
import Button from '../../Button2'

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
position: relative;
`
const Container = styled.div`
width: 95%;
margin: 0 auto;

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em) {
    width: 85%;
}

@media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    &>*:last-child{
        width: 80%;
    }
}
@media (max-width: 40em) {
    

    &>*:last-child{
        width: 90%;
    }
}
`

const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-contnet: center;
align-items: center;

@media (max-width: 40em) {
    min-height: 50vh;
}
`

const Title = styled.h2`
    font-size: ${props => props.theme.fontxxl};
    text-transform: capitalize;
    width: 80%;
    color: ${props => props.theme.body};
    align-self: flex-start;
    margin: 0 auto;

    @media (max-width: 64em) {
        width: 100%;
        text-align: center;
    }
    @media (max-width: 40em) {
        font-size: ${props => props.theme.fontxl};

    }
    @media (max-width: 30em) {
        font-size: ${props => props.theme.fontlg};

    }
`

const SubText = styled.p`
    font-size: ${props => props.theme.fontlg};
    text-transform: normal;
    width: 80%;
    color: ${props => props.theme.body};
    align-self: flex-start;
    margin: 1rem auto;
    font-weight:400;
    @media (max-width: 64em) {
        width: 100%;
        text-align: center;
        font-size: ${props => props.theme.fontmd};

    }
    @media (max-width: 40em) {
        font-size: ${props => props.theme.fontmd};

    }
    @media (max-width: 30em) {
        font-size: ${props => props.theme.fontsm};

    }

`

const SubTextLight = styled.p`
    font-size: ${props => props.theme.fontmd};
    color: ${(props) => `rgba(${props.theme.textRgba},0.6)`};
    width: 80%;
    align-self: flex-start;
    margin: 1rem auto;
    font-weight:400;

    @media (max-width: 64em) {
        width: 100%;
        text-align: center;
        font-size: ${props => props.theme.fontsm};

    }
    @media (max-width: 40em) {
        font-size: ${props => props.theme.fontsm};

    }
    @media (max-width: 30em) {
        font-size: ${props => props.theme.fontxs};

    }
`

const ButtonContainer = styled.div`
width: 80%;
margin: 1rem auto;
display: flex;
align-self: flex-start;

@media (max-width: 64em) {
    width: 100%;
    button{
        margin: 0 auto;
    }

}

`

const About = () => {
    return (
        <Section id="about">
            <Container>
                <Box> <Carousel /> </Box>
                <Box> 
                <Title>
                Welcome To The <br /> Club Finance ESLSCA.
                </Title>
                <SubText>
                The Club Finance ESLSCA is a non-profit association that aims to inform and make the theories of classical finance accessible to those curious about the financial world. It also strives to be a source of new ideas in the sector. 
                </SubText>
                <SubTextLight>
                We are committed to an educational purpose, with the organization of events (Afterworks, Conferences, Visits) and the production of knowledge/skills (Competitions, Newsletters).
                </SubTextLight>
                <ButtonContainer>
                <Button text="DOWNLOAD OUR BROCHURE" link="#" />
                </ButtonContainer>
                </Box>
            </Container>
        </Section>
    )
}

export default About