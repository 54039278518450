import React from 'react';
import styled from 'styled-components'
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Typewriter from 'typewriter-effect';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import img1 from './bighead-1.png';
import img2 from './bighead-2.jpg';
import img3 from './bighead-3.jpg';

const Section = styled.section`
height: 668px;
width: 100%;
background-color: ${props => props.theme.text};
display: flex;
justify-content: flex-end;
align-items: right;
position: relative;
margin: 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  
`

const Headf = () => {
  return (
      <Section>
        <Swiper
          autoplay={{
            delay:5,
            disableOnInteraction:true,
          }}
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
              <Image src={img1} alt="Logo" />
          </SwiperSlide>
          <SwiperSlide>
              <Image src={img2} alt="Logo" />
          </SwiperSlide>
          <SwiperSlide>
              <Image src={img3} alt="Logo" />
          </SwiperSlide>
        </Swiper>
      </Section>
    );
}

export default Headf;