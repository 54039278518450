import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
width: 100%;
heinght: 100%;
`

const Finance2 = () => {
    return (
        <Section id="finance2">Finance2</Section>
    )
}

export default Finance2;