import React from 'react'
import styled from 'styled-components'
import Banner from './Banner'
import Logo from './Logo'
import Instagram from '../Icons/Instagram'
import Youtube from '../Icons/Youtube'
import Twitter from '../Icons/Twitter'
import LinkedIn from '../Icons/LinkedIn'

const Section = styled.section`
    min-height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.body};
    position: relative;
    color: ${(props) => props.theme.body};
    display: flex;
    flex-direction: column;
`
const Container = styled.div`
    width: 75%;
    margin:  2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em){
        width: 90%;
    }
`
const Left = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 48em){
    width: 100%;

    hi{
        font-size: ${props => props.theme.fontxxxl};
    }
}
`

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;

&>*{
    padding-right: 0.5rem;
    transform: all 0.2s ease;

    &:hover{
        transform: scale(1.2);
    }
}
`
const MenuItems = styled.ul`
list-style:none;
width: 50%;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-gap: 1rem;
color: ${(props) => props.theme.text};

@media (max-width: 48em){
    display: none;
}
`
const Item = styled.li`
width: fit-content;
cursor: pointer;

&::after{
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: ${props => props.theme.text};
    transition: width 0.3s ease;

}
&:hover::after{
    width: 100%;

}
`

const Bottom = styled.div`
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.text};

@media (max-width: 48em){
    flex-direction: column;
    width: 50%;
    text-align: center;

    span{
        margin-bottom: 3rem;
    }
}
`

const Footer = () => {

const scrollTo = (id) => {

    let element = document.getElementById(id);

    element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'

    })
}

    return (
        <Section>
            <Banner />
            <Container>
        <Left>
        <Logo />
        <IconList>
            <a href="https://www.instagram.com/club_finance_eslsca/" target='_blank'
            rel="noopener"
            >
                <Instagram />
            </a>
            <a href="https://www.linkedin.com/company/club-finance-eslsca/" target='_blank'
            rel="noopener"
            >
                <LinkedIn />
            </a>
            <a href="https://www.youtube.com/@ClubFinanceESLSCA" target='_blank'
            rel="noopener"
            >
                <Youtube />
            </a>
            <a href="https://twitter.com/cfinance_eslsca" target='_blank'
            rel="noopener"
            >
                <Twitter />
            </a>
        </IconList>
        </Left>
        <MenuItems>
            <Item onClick={() => scrollTo('home')} >Home</Item>
            <Item onClick={() => scrollTo('about')} >About</Item>
            <Item onClick={() => scrollTo('finance')} >Finance</Item>

            <Item onClick={() => scrollTo('events')} >Events</Item>
            <Item onClick={() => scrollTo('team')} >Our Team</Item>
            <Item onClick={() => scrollTo('faq')} >Faq</Item>
 
        </MenuItems>
            </Container>
            <Bottom>
                <span>
                    &copy; {new Date().getFullYear()} Club Finance ESLSCA. All rights reserved.
                </span>
                <span>
                    Coded by Baptiste D. &#10084; 
                </span>
            </Bottom>
        </Section>
    )
}

export default Footer