import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';

import logoImage from './logo_homepage.png';

const LogoImage = styled.img`
width: 60px;
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1,1.1);
}
`;

const Logo = () => {
  return (
    <Link to="/">
        <LogoImage src={logoImage} alt="Logo de la page d'accueil" />
    </Link>
  );
};

export default Logo; 
