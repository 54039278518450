import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import DrawSvg from '../../DrawSvg'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Section = styled.section`
min-height: 100vh;
width: 100vm;
background-color: ${props => props.theme.overbackground};
position: relative;
margin-top: auto;
`

const Title = styled.h1`
font-size: ${props => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => props.theme.text};
display: flex;
justify-content: center;
margin: 1rem auto; 
border-bottom: 2px solid ${props => props.theme.text};
width: fit-content; 
padding-top: 30px;
`

const Container = styled.div`
width: 70%;
height: 200vh;
background-color: ${props => props.theme.text};
margin : 0 auto;
display: flex; 
justify-content: center;
align-items: center;
position: relative;
`

const SvgContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const Items = styled.div`
list-style:none;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${props => props.theme.overbackground};

&>*:nth-of-type(2n +1){
    justify-content: start;

    div{
        border-radius: 50px 0 50px 0;
        text-align: right;
    }
    p{
        border-radius: 50px 0 50px 0;
    }
}
&>*:nth-of-type(2n){
    justify-content: end;
    div{
        border-radius: 0 50px 0 50px;
        text-align: left;
    }
    p{
        border-radius: 0 40px 0 40px;
    }
}
`

const Item = styled.li`
width: 100%;
height: 100%;
display: flex;
`

const ItemContainer = styled.div`
width: 40%;
height: fit-content; 
padding: 1rem;
border: 3px solid ${props => props.theme.text};
`

const Box = styled.p`
height: fit-content;
background-color: ${props => props.theme.carouselColor};
color: ${props => props.theme.body};
padding: 1rem;
position: relative;
border: 1px solid ${props => props.theme.body};
`

const SubTitle = styled.span`
display: block;
font-size: ${props => props.theme.fontxl};
text-transform: capitalize;
color: ${props => props.theme.body};
`
const Text = styled.span`
display: block;
font-size: ${props => props.theme.fontsm};
text-transform: none;
color: ${props => props.theme.body};

font-weight: 400;
margin: 0.5rem 0;
`

const GoalsItem = ({title, subtext, addToRef}) => {

    return(
        <Item ref={addToRef}>
            <ItemContainer>
                <Box>
                    <SubTitle>{title}</SubTitle>
                    <Text>{subtext}</Text>
                </Box>
            </ItemContainer>
        </Item>
    )
}

const Goals = () => {

const revealRefs = useRef([]);
revealRefs.current = [];
gsap.registerPlugin(ScrollTrigger);

const addToRefs = (el) => {
    if(el && !revealRefs.current.includes(el)) {
        revealRefs.current.push(el);
    }
}

useLayoutEffect(() => {
let t1 = gsap.timeline();
revealRefs.current.forEach( (el, index) => {

    t1.fromTo(
        el.childNodes[0],
        {
            y: '0'
        },{
            y: '-30%',

            scrollTrigger:{
                id:`section-${index +1}`,
                trigger: el,
                start: 'top center+=200px',
                end:'bottom center',
                scrub:true,
            }
        }
    )
})

    return () => {
        t1.kill();

    }
}, [])

    return (
        <Section id="goals">
            <Title>Goals</Title>
            <Container>
                <SvgContainer>
                    <DrawSvg />
                </SvgContainer>
                <Items>
                <Item>&nbsp;</Item>
                    <GoalsItem addToRef={addToRefs} title="1- Spread knowledge" subtext="by organizing thematic conferences on current topics." />
                    <GoalsItem addToRef={addToRefs} title="2- Create opportunities" subtext="through visits to financial institutions and during evenings with our partners." />
                    <GoalsItem addToRef={addToRefs} title="3- Propose an innovative approach" subtext="by publishing a monthly newsletter and articles on finance." />
                    <GoalsItem addToRef={addToRefs} title="4- Develop skills" subtext="by offering debate workshops, featuring specialized educators, to shape your critical thinking and character." />
                    <GoalsItem addToRef={addToRefs} title="5- Encourage deep reflection" subtext="by organizing competitions (trading, private equity) and publishing students' research work." />
                </Items>
            </Container>
        </Section>
    )
}

export default Goals;