import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
width: 100%;
heinght: 100%;
`

const Contact = () => {
    return (
        <Section id="contact">Contact</Section>
    )
}

export default Contact;