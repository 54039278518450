
export const light = {
   
    body:"#0C1257",
    text:"#fff",
    bodyRgba : "255, 255, 255",
    textRgba:"32,32,32",
    overbackground:"#111e84",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',

    text1: '#242c73',
    text2: '#848cb3',
    text3: '#333a79',

    navHeight: '5rem',
}

export const dark = {
   
    body:"#202020",
    text:"#fff",
    bodyRgba :"32,32,32",
    textRgba:"255, 255, 255",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}


// Couleurs charte graphique //
// #0c1257 #d2d3e2 #5f6498 #111e84 #797eaf #848cb3 #444c89 #333a79 #242c73 //


