import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
width: 100%;
heinght: 100%;
`

const Schedule = () => {
    return (
        <Section id="schedule">Schedule</Section>
    )
}

export default Schedule;