import React from 'react'
import styled from 'styled-components'
import GIF from '../assets/Home Video.mp4'

const VideoContainer = styled.div`
width: 100%;
height: 90vh;
overflow: hidden;

video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 64em) {
    min-width: 40vh;
}
`

const CoverVideo = () => {
    return (
        <VideoContainer>
            <video src={GIF} type="video/mp4S"  autoPlay muted loop  />
        </VideoContainer>
    )
}

export default CoverVideo