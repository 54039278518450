import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
width: 100%;
min-height: 100vh;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
`

const Box = styled.div`
height: 100%;
width: 100%;
position: relative;
font-size: ${props => props.theme.fontxxl};
color: ${props => props.theme.body};

`

const Headt = () => {
    return (
        <Box>
            <Section id="headt">Work in progress...</Section>
        </Box>
        
    )
}

export default Headt;