import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { nationalities } from './Nationalities';
import { countries } from './Countries';
import { countryCodes } from './CountryCodes';

const Section = styled.section`
background-color: ${props => `rgba(${props.theme.textRgba},0.9)`};
`;

const Form = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 550px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
`;

const FormLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 10px; 
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 19px 0;
`;

const Footer = styled.div`
  text-align: center;
`;

const GenderSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin: 19px 0;
`;

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
`;

const CountryCodeSelect = styled.select`
  width: 30%;
  padding: 10px;
  margin: 19px 0;
`;

const MobileInput = styled.input`
  width: 70%;
  padding: 10px;
  margin: 19px 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: center;
  position: relative;
`;

const CheckboxLabel = styled.label`
  margin-right: 10px;
`;

const CheckboxInput = styled.input`
  margin-right: 5px;
`;

const CountryCodeOption = styled.option``;

const GenderOption = styled.option``;

function RegistrationForm() {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [gender, setGender] = useState(null);
    const [nationality, setNationality] = useState(null);
    const [postalAddress, setPostalAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState(null);
    const [countryCode, setCountryCode] = useState("");
    const [mobile, setMobile] = useState(null);
    const [email, setEmail] = useState(null);
    const [password,setPassword] = useState(null);
    const [confirmPassword,setConfirmPassword] = useState(null);
    const [isCheckedCGA, setIsCheckedCGA] = useState(false);
    const [isCheckedDroit, setIsCheckedDroit] = useState(false);
    const [isCheckedListe, setIsCheckedListe] = useState(false);

    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "firstName"){
            setFirstName(value);
        }
        if(id === "lastName"){
            setLastName(value);
        }
        if(id === "gender"){
            setGender(value);
        }
        if(id === "nationality"){
            setNationality(value);
        }
        if(id === "postalAddress"){
            setPostalAddress(value);
        }
        if(id === "postalCode"){
            setPostalCode(value);
        }
        if(id === "city"){
            setCity(value);
        }
        if(id === "country"){
            setCountry(value);
        }
        if(id === "mobile"){
            setMobile(value);
        }
        if(id === "email"){
            setEmail(value);
        }
        if(id === "password"){
            setPassword(value);
        }
        if(id === "confirmPassword"){
            setConfirmPassword(value);
        }

    }

    const handleDateChange = date => {
        setStartDate(date);
    };

    const handleSubmit = () => {
        console.log(firstName, lastName, startDate, gender, nationality, postalAddress, postalCode, city, country, countryCode, mobile, email, password, confirmPassword, isCheckedCGA, isCheckedDroit, isCheckedListe);
    };
    
    const handleCheckboxChange = () => {
        setIsCheckedCGA(!isCheckedCGA);
        setIsCheckedDroit(!isCheckedDroit);
        setIsCheckedListe(!isCheckedListe);
    };      
    
  return (
    <Section className="form">
      <Form className="form-body">
        <div className="username">
          <FormLabel className="form__label" htmlFor="firstName">
            First Name
          </FormLabel>
          <InputContainer>
            <Input
              className="form__input"
              type="text"
              value={firstName} 
              onChange = {(e) => handleInputChange(e)}
              id="firstName"
              placeholder="First Name"
            />
          </InputContainer>
        </div>
        <div className="lastname">
          <FormLabel className="form__label" htmlFor="lastName">
            Last Name
          </FormLabel>
          <InputContainer>
            <Input
              type="text"
              name=""
              id="lastName"
              value={lastName}
              className="form__input"
              onChange = {(e) => handleInputChange(e)}
              placeholder="Last Name"
            />
          </InputContainer>
        </div>
        <div className="birthday">
          <FormLabel className="form__label" htmlFor="birthday">
            Birthday
          </FormLabel>
          <InputContainer>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
            />
          </InputContainer>
        </div>
        <div className="gender">
            <FormLabel className="form__label" htmlFor="gender">
                Gender
            </FormLabel>
            <InputContainer>
                <GenderSelect
                    id="gender"
                    value={gender}
                    className="form__input"
                    onChange={(e) => handleInputChange(e)}
                >
                    <GenderOption value="">Select Gender</GenderOption>
                    <GenderOption value="Man">Man</GenderOption>
                    <GenderOption value="Woman">Woman</GenderOption>
                    <GenderOption value="Unprecised">Unprecised</GenderOption>
                </GenderSelect>
            </InputContainer>
        </div>
        <div className="nationality">
          <FormLabel className="form__label" htmlFor="nationality">
            Nationality
          </FormLabel>
          <InputContainer>
            {/* Replace Input with select element */}
            <select
              id="nationality"
              value={nationality}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select Nationality</option>
              {/* Map through the nationalities array and create an option for each */}
              {nationalities.map((nat, index) => (
                <option key={index} value={nat}>
                  {nat}
                </option>
              ))}
            </select>
          </InputContainer>
        </div>
        <div className="postalAddress">
          <FormLabel className="form__label" htmlFor="postalAddress">
            Postal Address
          </FormLabel>
          <InputContainer>
            <Input
              type="text"
              name=""
              id="postalAddress"
              value={postalAddress}
              className="form__input"
              onChange = {(e) => handleInputChange(e)}
              placeholder="Postal Address"
            />
          </InputContainer>
        </div>
        <div className="postalCode">
          <FormLabel className="form__label" htmlFor="postalCode">
            Postal Code
          </FormLabel>
          <InputContainer>
            <Input
              type="text"
              name=""
              id="postalCode"
              value={postalCode}
              className="form__input"
              onChange = {(e) => handleInputChange(e)}
              placeholder="Postal Code"
            />
          </InputContainer>
        </div>
        <div className="city">
          <FormLabel className="form__label" htmlFor="city">
            City
          </FormLabel>
          <InputContainer>
            <Input
              type="text"
              name=""
              id="city"
              value={city}
              className="form__input"
              onChange = {(e) => handleInputChange(e)}
              placeholder="City"
            />
          </InputContainer>
        </div>
        <div className="country">
          <FormLabel className="form__label" htmlFor="country">
            Country
          </FormLabel>
          <InputContainer>
            {}
            <select
              id="country"
              value={country}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select Country</option>
              {}
              {countries.map((nat, index) => (
                <option key={index} value={nat}>
                  {nat}
                </option>
              ))}
            </select>
          </InputContainer>
        </div>
        <div className="mobile">
          <FormLabel className="form__label" htmlFor="mobile">
            Mobile Phone
          </FormLabel>
          <MobileContainer>
            <CountryCodeSelect
              id="countryCode"
              value={countryCode}
              className="form__input"
              onChange={(e) => setCountryCode(e.target.value)}
            >
              {countryCodes.map((country, index) => (
                <CountryCodeOption key={index} value={country.code}>
                  {`+${country.code}`}
                </CountryCodeOption>
              ))}
            </CountryCodeSelect>
            <MobileInput
              type="text"
              name=""
              id="mobile"
              value={mobile}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
              placeholder="Mobile Phone"
            />
          </MobileContainer>
        </div>
        <div className="email">
          <FormLabel className="form__label" htmlFor="email">
            Email
          </FormLabel>
          <InputContainer>
            <Input
              type="email"
              id="email"
              className="form__input"
              value={email} 
              onChange = {(e) => handleInputChange(e)}
              placeholder="Email"
            />
          </InputContainer>
        </div>
        <div className="password">
          <FormLabel className="form__label" htmlFor="password">
            Password
          </FormLabel>
          <InputContainer>
            <Input
              className="form__input"
              type="password"
              id="password"
              value={password} 
              onChange = {(e) => handleInputChange(e)}
              placeholder="Password"
            />
          </InputContainer>
        </div>
        <div className="confirm-password">
          <FormLabel className="form__label" htmlFor="confirmPassword">
            Confirm Password
          </FormLabel>
          <InputContainer>
            <Input
              className="form__input"
              type="password"
              id="confirmPassword"
              value={confirmPassword} 
              onChange = {(e) => handleInputChange(e)}
              placeholder="Confirm Password"
            />
          </InputContainer>
        </div>
      </Form>
      <div className="CGA">
        <CheckboxContainer>
          <CheckboxInput
            type="checkbox"
            id="CGA"
            checked={isCheckedCGA}
            onChange={() => setIsCheckedCGA(!isCheckedCGA)}
          />
          <CheckboxLabel htmlFor="CGA">CGA</CheckboxLabel>
        </CheckboxContainer>
      </div>
      <div className="Droit à l'image">
        <CheckboxContainer>
          <CheckboxInput
            type="checkbox"
            id="Droit"
            checked={isCheckedDroit}
            onChange={() => setIsCheckedDroit(!isCheckedDroit)}
          />
          <CheckboxLabel htmlFor="Droit">Droit à l'image</CheckboxLabel>
        </CheckboxContainer>
      </div>
      <div className="Liste de diffusion">
        <CheckboxContainer>
          <CheckboxInput
            type="checkbox"
            id="Liste"
            checked={isCheckedListe}
            onChange={() => setIsCheckedListe(!isCheckedListe)}
          />
          <CheckboxLabel htmlFor="Liste">Liste de diffusion</CheckboxLabel>
        </CheckboxContainer>
      </div>
      <Footer className="footer">
        <button onClick={()=>handleSubmit()} type="submit" className="btn">
          Register now
        </button>
      </Footer>
    </Section>
  );
}

export default RegistrationForm;