import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import Accordeon from '../../Accordeon'


const Section = styled.section`
    min-height: 100vh;
    height: auto;
    width: 100vw;
    background-color: ${props => props.theme.body};
    position: relative;
    color: ${(props) => props.theme.text};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Title = styled.h1`
    font-size: ${(props) => props.theme.fontxxl};
    text-transform: uppercase;
    color: ${(props) => props.theme.text};
    margin: 1rem auto;
    border-bottom: 2px solid ${(props) => props.theme.carouselColor};
    width: fit-content;

    @media (max-width: 48em){
    ${(props) => props.theme.fontxxl};   
    }
`
const Container = styled.div`
    width: 75%;
    margin: 2rem auto;

    display: flex;
    justify-content: space-between;
    align-content: center;

@media (max-width: 64em){
    width: 80%;
    }
@media (max-width: 48em){
    width: 90%;
    flex-direction: column;

    &>*:last-child{
        &>*:first-child{

        margin-top: 0;
        }

    }
}
`

const Box = styled.div`
    width: 45%;
@media (max-width: 64em){
    width: 90%;
    align-self: center;
    }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {

    let element = ref.current;

    ScrollTrigger.create({
        trigger: element,
        start:'top top',
        end:'bottom top',
        pin:true,
        pinSpacing:false,
        scrub:true,
    });

    return () => {
        ScrollTrigger.kill();
    };
}, [])

    return (
        <Section ref={ref} id="faq">
            <Title>Faq</Title>
        <Container>
<Box>
    <Accordeon title="IS THE CLUB ONLY RESERVED FOR ESLSCA STUDENTS?" >
    The club is open to all young people aged 18 to 35, whether you are from ESLSCA, Sup de Luxe, SMS, or an Alumni
    </Accordeon>
    <Accordeon title="DO YOU ONLY DO TRADING?" >
    The club is open to all young people aged 18 to 35, whether you are from ESLSCA, Sup de Luxe, SMS, or an Alumni
    </Accordeon>
    <Accordeon title="ARE THERE PROFESSORS IN THE CLUB?" >
    The club is open to all young people aged 18 to 35, whether you are from ESLSCA, Sup de Luxe, SMS, or an Alumni
    </Accordeon>
</Box>
<Box>
    <Accordeon title="DOES THE CLUB IDENTIFY ITSELF AS A THINK TANK?" >
    The club is open to all young people aged 18 to 35, whether you are from ESLSCA, Sup de Luxe, SMS, or an Alumni
    </Accordeon>
    <Accordeon title="IS THIS THE CLUB FOR FUTURE WALL STREET WOLVES?" >
    The club is open to all young people aged 18 to 35, whether you are from ESLSCA, Sup de Luxe, SMS, or an Alumni
    </Accordeon>
    <Accordeon title="WHY YOUR CLUB AND NOT ANOTHER ONE?" >
    The club is open to all young people aged 18 to 35, whether you are from ESLSCA, Sup de Luxe, SMS, or an Alumni
    </Accordeon>
</Box>
        </Container>
        </Section>
    )
}

export default Faq