import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import { light } from './styles/Themes';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation from './components/Navigation';
import Home from './components/sections/Welcome/Home';
import About from './components/sections/Welcome/About';
import Finance1 from './components/sections/Finance/Finance1';
import Finance2 from './components/sections/Finance/Finance2';
import Headf from './components/sections/Finance/Headf';
import Event from './components/sections/Events/Event';
import Heade from './components/sections/Events/Heade';
import Schedule from './components/sections/Events/Schedule';
import Joining from './components/sections/Join/Joining';
import RegistrationForm from './components/sections/Join/RegistrationForm';
import Teams from './components/sections/Team/Teams';
import Headt from './components/sections/Team/Headt';
import Newsletter from './components/sections/Newsletters/Newsletter';
import ShareList from './components/sections/Newsletters/ShareList';
import Headn from './components/sections/Newsletters/Headn';
import Competition from './components/sections/Competitions/Competition';
import Headc from './components/sections/Competitions/Headc';
import Faq from './components/sections/Welcome/Faq';
import Goals from './components/sections/Welcome/Goals';
import Contacts from './components/sections/Contact/Contacts';
import Headcontact from './components/sections/Contact/Headc';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function Welcome() {
  return (
    <>
      <Home />
      <About />
      <Goals />
      <Faq />
    </>
  );
}

function Finance() {
  return (
    <>
      <Headf />
      <Finance1 />
      <Finance2 />
    </>
  )
}

function Newsletters() {
  return (
    <>
      <Headn />
      <Newsletter />
      <ShareList />
    </>
  )
}

function Events() {
  return (
    <>
      <Heade />
      <Event />
      <Schedule />
    </>
  )
}

function Competitions() {
  return (
    <>
      <Headc />
      <Competition />
    </>
  )
}

function Team() {
  return (
    <>
      <Headt />
      <Teams />
    </>
  )
}

function Contact(){
  return (
    <>
      <Headcontact />
      <Contacts />
    </>
  )
}

function Join(){
  return (
    <>
      <Joining />
      <RegistrationForm />
    </>
  )
}

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/Finance" element={<Finance />} />
            <Route path="/Newsletters" element={<Newsletters />} />
            <Route path="/Events" element={<Events />} />
            <Route path="/Team" element={<Team />} />
            <Route path="/Competitions" element={<Competitions />} />
            <Route path="/Join" element={<Join />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>
          <Footer />
          <ScrollToTop />
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
