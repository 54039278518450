import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import Button from './Button'
import img1 from '../assets/Nfts/bighead-1.svg';
import img2 from '../assets/Nfts/bighead-2.svg';
import img3 from '../assets/Nfts/bighead-3.svg';
import img4 from '../assets/Nfts/bighead-4.svg';

const Section = styled.section`
    width: 100vw;
    height: 25rem;
    position: relative;
    border-top: 2px solid ${props => props.theme.text};
    border-top: 2px solid ${props => props.theme.text};

    background-color: ${props => `rgba(${props.theme.textRgba},0.9)`};

    display: flex;
    justify-content: space-between;
    align-items: center;

    overflow: hidden;

    @media (max-width: 48em) {
        height: 15rem;
        flex-direction: column;
    }
`

const ImgContainer = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.2;

    img{
        width: 15rem;
        height: auto;
    }

    @media (max-width: 48em) {
        img{
            width: 10rem;
            height: auto;
    }
    }
`

const Title = styled.h1`
    font-size: ${props => props.theme.fontxxxl};
    color: ${props => props.theme.text};
    padding: 1rem 15rem;
    z-index: 10;
    width: 35%;
    text-transform: capitalize;
    text-shadow: 1px 1px 2px ${props => props.theme.text};

    @media (max-width: 64em) {
        font-size: ${props => props.theme.fontxxl};
        text-align: center;
        width: 40%;
    }
    @media (max-width: 48em) {
        font-size: ${props => props.theme.fontxl};
            width: 80%;
            padding: 2rem 0;
            margin-top: 0.5rem;
    }
`


const BtnContainer = styled.div`
    width: 35%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 48em){
        width: 100%;
        justify-content: center;
    }
`

const JoinNow = styled(Link)`
display: inline-block;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
outline: none;
border: none;
font-weight: 600;
font-size: ${props => props.theme.fontlg};
padding: 1.5rem 3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;

@media (max-width: 48em){
    padding: 1rem 2rem;
    margin-bottom: 2.5rem;
}
&:hover{
    transform: scale(0.9);
}

&::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;

}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
`

const Banner = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return(
        <Section>
            <ImgContainer>
                <img src={img1} alt="Club Finance ESLSCA" />
                <img src={img2} alt="Club Finance ESLSCA" />
                <img src={img3} alt="Club Finance ESLSCA" />
                <img src={img4} alt="Club Finance ESLSCA" />
            </ImgContainer>
            <Title> Club Finance ESLSCA </Title>
            <BtnContainer>
            <JoinNow to="/join" onClick={scrollToTop}>
                Join Now
            </JoinNow>
            </BtnContainer>
        </Section>
    )
}

export default Banner